* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


html {
  height: 100%;
  width: 100%;
  margin: 0;
  background: linear-gradient(127deg, rgba(2, 0, 26, 0.8), rgba(19, 0, 37, 0.9), rgba(43, 4, 0, 0.8)), url(../public/space.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-color: #000;

}

body {
  height: 97%;
  width: 100%;
  position: relative;
  color: rgb(253, 222, 155);
  font-family: 'Crimson Pro', serif;
  background: transparent;
}

.App {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  transition: 4s;
  color: #fff;
}

h1 {
  font-size: 4rem;
  width: 100%;
}

h2 {
  font-size: 2.5rem;
  display: block;
  width: 100%;
}

h3 {
  text-align: center;
  font-size: 1.5rem;
}

p, .moment {
  font-size: 1.5rem;
  line-height: 1.8rem;
  display: block;
  margin-bottom: 3rem;
}

.moment {
  margin-top: 2rem;
}

.desc {
  text-align:center;
  font-size: 2.2rem;
  font-style: italic;
  line-height: 2.2rem;
}

.range {
  font-size: 1.75rem;
  font-style: italic;
  line-height: 2rem;
  margin-bottom: 1.50rem;
}


footer p {
  font-size: .8rem;
  padding-right: .5rem;
  z-index: 1;
}

.content {
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

nav {
  margin-top: 2%;
}

.navbar-brand {
  background: url('../public/sun.png') no-repeat center center;
  background-size: contain, cover;
  width: 80px;
  height: 80px;
}

.menu-toggler{
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 170px;
  height: 170px;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}


.menu-toggler:hover + label:after{
  background: url('../public/sun.png') no-repeat center center;
  background-size: contain, cover;
}

.menu-toggler:checked + label{
  background:transparent;
}

.menu-toggler:checked + label:before,
.menu-toggler:checked + label:after {
  top: 0rem;
  left: 0rem;
  width: 250px;
  height: 250px;
  transform-origin: 50% 50%;
}

.menu-toggler:checked + label:before {
  transform: rotate(360deg);
}

.menu-toggler:checked + label:after {
  transform: rotate(360deg);
}

.menu-toggler:checked ~ ul .menu-item {
  opacity: 1;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(1){
  transform: rotate(-15deg) translateX(-200%);
  background: url('../public/aries.png') no-repeat center center;
  background-size: 80px 80px;
}

.nav-item:nth-child(1) a {
  transform: rotate(95deg);
  background: url('../public/aries.png') no-repeat center center;
  background-size: 30px 30px;
  width: 40px;
  height: 40px;
  transition: 0.2s;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(2){
  transform: rotate(15deg) translateX(-200%);
  background: url('../public/taurus.png') no-repeat center center;
  background-size: 80px 80px;
}

.nav-item:nth-child(2) a {
  transform: rotate(90deg);
  background: url('../public/taurus.png') no-repeat center center;
  background-size: 30px 30px;
  width: 40px;
  height: 40px;
  transition: 0.2s;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(3){
  transform: rotate(45deg) translateX(-200%);
  background: url('../public/gemini.png') no-repeat center center;
  background-size: 80px 80px;
}

.nav-item:nth-child(3) a {
  transform: rotate(90deg);
  background: url('../public/gemini.png') no-repeat center center;
  background-size: 30px 30px;
  width: 40px;
  height: 40px;
  transition: 0.2s;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(4){
  transform: rotate(75deg) translateX(-200%);
  background: url('../public/cancer.png') no-repeat center center;
  background-size: 80px 80px;
}

.nav-item:nth-child(4) a {
  transform: rotate(90deg);
  background: url('../public/cancer.png') no-repeat center center;
  background-size: 28px 28px;
  width: 40px;
  height: 40px;
  transition: 0.2s;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(5){
  transform: rotate(105deg) translateX(-200%);
  background: url('../public/leo.png') no-repeat center center;
  background-size: 90px 90px;
}

.nav-item:nth-child(5) a {
  transform: rotate(90deg);
  background: url('../public/leo.png') no-repeat center center;
  background-size: 32px 32px;
  width: 40px;
  height: 40px;
  transition: 0.2s;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(6){
  transform: rotate(135deg) translateX(-200%);
  background: url('../public/virgo.png') no-repeat center center;
  background-size: 90px 90px;
}

.nav-item:nth-child(6) a {
  transform: rotate(95deg);
  background: url('../public/virgo.png') no-repeat center center;
  background-size: 30px 30px;
  width: 40px;
  height: 40px;
  transition: 0.2s;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(7){
  transform: rotate(165deg) translateX(-200%);
  background: url('../public/libra.png') no-repeat center center;
  background-size: 80px 80px;
}

.nav-item:nth-child(7) a {
  transform: rotate(91deg);
  background: url('../public/libra.png') no-repeat center center;
  background-size: 30px 30px;
  width: 40px;
  height: 40px;
  transition: 0.2s;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(8){
  transform: rotate(195deg) translateX(-200%);
  background: url('../public/scorpio.png') no-repeat center center;
  background-size: 80px 80px;
}

.nav-item:nth-child(8) a {
  transform: rotate(90deg);
  background: url('../public/scorpio.png') no-repeat center center;
  background-size: 32px 32px;
  width: 40px;
  height: 40px;
  transition: 0.2s;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(9){
  transform: rotate(225deg) translateX(-200%);
  background: url('../public/sagittarius.png') no-repeat center center;
  background-size: 80px 80px;
}

.nav-item:nth-child(9) a {
  transform: rotate(90deg);
  background: url('../public/sagittarius.png') no-repeat center center;
  background-size: 32px 32px;
  width: 40px;
  height: 40px;
  transition: 0.2s;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(10){
  transform: rotate(255deg) translateX(-200%);
  background: url('../public/capricorn.png') no-repeat center center;
  background-size: 80px 80px;
}

.nav-item:nth-child(10) a {
  transform: rotate(98deg);
  background: url('../public/capricorn.png') no-repeat center center;
  background-size: 28px 28px;
  width: 40px;
  height: 40px;
  transition: 0.2s;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(11){
  transform: rotate(285deg) translateX(-200%);
  background: url('../public/aquarius.png') no-repeat center center;
  background-size: 80px 80px;
}

.nav-item:nth-child(11) a {
  transform: rotate(90deg);
  background: url('../public/aquarius.png') no-repeat center center;
  background-size: 32px 32px;
  width: 40px;
  height: 40px;
  padding-top: 5px;
  transition: 0.2s;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(12){
  transform: rotate(315deg) translateX(-200%);
  background: url('../public/pisces.png') no-repeat center center;
  background-size: 80px 80px;
}

.nav-item:nth-child(12) a {
  transform: rotate(80deg);
  background: url('../public/pisces.png') no-repeat center center;
  background-size: 28px 28px;
  width: 40px;
  height: 40px;
  transition: 0.2s;
}

.nav-item:nth-child(1) a:hover, 
.nav-item:nth-child(2) a:hover,
.nav-item:nth-child(3) a:hover,
.nav-item:nth-child(4) a:hover,
.nav-item:nth-child(5) a:hover,
.nav-item:nth-child(6) a:hover,
.nav-item:nth-child(7) a:hover,
.nav-item:nth-child(8) a:hover,
.nav-item:nth-child(9) a:hover,
.nav-item:nth-child(10) a:hover,
.nav-item:nth-child(11) a:hover,
.nav-item:nth-child(12) a:hover {
  background-size: 35px 35px;
}

.menu-toggler:checked ~ ul .menu-item a {
  pointer-events: auto;
}

.menu-toggler + label {
  width: 250px;
  height: 250px;
  display: block;
  z-index: 1;
  transition: transform 0.5s, top 0.5s;
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin:auto;
}

.menu-toggler + label:after {
  width: 250px; 
  height: 250px;
  display: block;
  z-index: 1;
  border-radius: 2.5px;
  background: url('../public/sun.png') no-repeat center center;
  background-size: contain, cover;
  transition: transform 0.5s, top 0.5s;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin:auto;
}

.menu-toggler + label:before {
  top:10px;
}

.menu-toggler + label:after{
  top: -10px;
}


.menu-item {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin:auto;
  width: 110px;
  height: 110px;
  display: block;
  opacity: 0;
  transition: 0.5s;
}

.menu-item a{
  display: block;
  widows: inherit;
  height: inherit;
  text-align: center;
  text-decoration: none;
  pointer-events: none;
  transition: 0.2s;
  
}


.menu-toggler:checked ~ ul .menu-item:nth-child(1):hover{
  transform: rotate(-15deg) translateX(-200%);
  background-size: 115px 115px;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(2):hover{
  transform: rotate(15deg) translateX(-200%);
  background-size: 115px 115px;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(3):hover{
  transform: rotate(45deg) translateX(-200%);
  background-size: 115px 115px;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(4):hover{
  transform: rotate(75deg) translateX(-200%);
  background-size: 115px 115px;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(5):hover{
  transform: rotate(105deg) translateX(-200%);
  background-size: 115px 115px;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(6):hover{
  transform: rotate(135deg) translateX(-200%);
  background-size: 115px 115px;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(7):hover{
  transform: rotate(165deg) translateX(-200%);
  background-size: 115px 115px;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(8):hover{
  transform: rotate(195deg) translateX(-200%) scale(115%);
  background-size: 115px 115px;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(9):hover{
  transform: rotate(225deg) translateX(-200%);
  background-size: 115px 115px;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(10):hover{
  transform: rotate(255deg) translateX(-200%);
  background-size: 115px 115px;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(11):hover{
  transform: rotate(285deg) translateX(-200%);
  background-size: 115px 115px;
}

.menu-toggler:checked ~ ul .menu-item:nth-child(12):hover{
  transform: rotate(315deg) translateX(-200%);
  background-size: 120px 120px;
}

.aries, .sagittarius, .taurus, .gemini, .cancer, .leo, .virgo, .libra, .scorpio, .aquarius, .pisces {
  width: 90px;
  max-height: 90px;
  max-width: 90px;
  height: auto;
  float: right;
  margin-right: .25rem;
}

.capricorn {
  width: 85px;
  max-height: 85px;
  max-width: 85px;
  height: auto;
  float: right;
  margin-right: .25rem;
}

.pisces {
  transform: rotate(79deg);
}

.scorpio, .aquarius {
  transform: rotate(89deg);
}

.taurus, .gemini, .cancer, .leo, .capricorn {
  transform: rotate(90deg);
}

.libra {
  transform: rotate(91deg);
}

.virgo {
  transform: rotate(93deg);
}

.aries, .sagittarius {
  transform: rotate(97deg);
}


/* overrides */

.pb-5, .py-5 {
  padding-bottom: 1rem !important;
  padding-top: .5rem !important;
}

.bg-dark {
  background-color: transparent !important;
}

.navbar {
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;
  padding-bottom: 0;
}


.navbar-brand {
  width: 90px;
  max-width: 90px;
  background: url('../public/sun.png') no-repeat left center;
  background-size: contain, cover;
  margin-left: 0;
}


.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

}

.card {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  background-color: transparent;
}

.buttons {
  justify-content: center;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 2rem;
}


.buttons > a {
  min-width: 110px;
  max-width: 300px;
  width: 100%;
  padding: .5rem;
  font-size: 1.5rem;
  margin: 5%;
  z-index: 10;
  color: rgb(253, 222, 155);
  text-decoration: none;
  border: 1px solid  rgb(253, 222, 155);
  border-radius: 3px;
}

.buttons > a:hover {
  transform: scale(1.1);
  background-color:rgba(255, 255, 255, 0.1);
  transition: all .3s ease-out;
}



footer {
  font-size: .6rem;
  padding: 1rem;
}

.fixed-bottom {
  z-index: unset;
}


@media only screen and (max-width: 768px) {

  h1 {
    font-size: 3rem;
  }
    
  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }

  .menu-item {
    width: 80px;
    height: 80px;
  }

  .menu-toggler:checked + label:before,
  .menu-toggler:checked + label:after {
    width: 200px;
    height: 200px;
  }

  .menu-toggler + label:after {
    width: 200px;
    height: 200px;
  }

  .menu-toggler{
    width: 200px;
    height: 200px;
  }
  .menu-toggler + label {
    width: 200px;
    height: 200px;
  }
  .menu-toggler:checked ~ ul .menu-item:nth-child(1){
    background-size: 60px 60px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(2){
    background-size: 60px 60px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(3){
    background-size: 60px 60px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(4){
    background-size: 60px 60px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(5){
    background-size: 60px 60px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(6){
    background-size: 60px 60px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(7){
    background-size: 60px 60px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(8){
    background-size: 60px 60px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(9){
    background-size: 60px 60px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(10){
    background-size: 60px 60px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(11){
    background-size: 60px 60px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(12){
    background-size: 60px 60px;
  }


  .nav-item:nth-child(1) a, 
  .nav-item:nth-child(2) a, 
  .nav-item:nth-child(3) a, 
  .nav-item:nth-child(4) a, 
  .nav-item:nth-child(5) a, 
  .nav-item:nth-child(6) a, 
  .nav-item:nth-child(7) a, 
  .nav-item:nth-child(8) a, 
  .nav-item:nth-child(9) a,
  .nav-item:nth-child(10) a,
  .nav-item:nth-child(11) a,
  .nav-item:nth-child(12) a
   {
    background-size: 20px 20px;
    width: 30px;
    height: 30px;
  }

  .menu-toggler:checked ~ ul .menu-item:nth-child(1):hover, 
  .menu-toggler:checked ~ ul .menu-item:nth-child(2):hover, 
  .menu-toggler:checked ~ ul .menu-item:nth-child(3):hover,
  .menu-toggler:checked ~ ul .menu-item:nth-child(4):hover,
  .menu-toggler:checked ~ ul .menu-item:nth-child(5):hover,
  .menu-toggler:checked ~ ul .menu-item:nth-child(6):hover,
  .menu-toggler:checked ~ ul .menu-item:nth-child(7):hover,
  .menu-toggler:checked ~ ul .menu-item:nth-child(8):hover,
  .menu-toggler:checked ~ ul .menu-item:nth-child(9):hover,
  .menu-toggler:checked ~ ul .menu-item:nth-child(10):hover,
  .menu-toggler:checked ~ ul .menu-item:nth-child(11):hover,
  .menu-toggler:checked ~ ul .menu-item:nth-child(12):hover {
    background-size: 80px 80px;
  }

  .navbar-nav {
    flex-direction: row;
    
  }

  .nav-item:nth-child(1) a:hover, 
  .nav-item:nth-child(2) a:hover,
  .nav-item:nth-child(3) a:hover,
  .nav-item:nth-child(4) a:hover,
  .nav-item:nth-child(5) a:hover,
  .nav-item:nth-child(6) a:hover,
  .nav-item:nth-child(7) a:hover,
  .nav-item:nth-child(8) a:hover,
  .nav-item:nth-child(9) a:hover,
  .nav-item:nth-child(10) a:hover,
  .nav-item:nth-child(11) a:hover,
  .nav-item:nth-child(12) a:hover {
    background-size: 30px 30px;
  }

  .range {
    display: block;
  }

  .aries, .sagittarius, .taurus, .gemini, .cancer, .leo, .virgo, .libra, .scorpio, .capricorn, .aquarius, .pisces {
    width: 60px;
    height: auto;
  }
}

@media only screen and (max-height: 599px) {
  footer {
    display: none;
  }
}


@media only screen and (max-width: 575px) {
  .navbar {
    margin-left:0;
    margin-right: 0;
  }
  .nav-item {
    width: 28px;
    height: 28px;
  }
}


@media only screen and (max-width: 481px) , 
screen and (max-height: 499px) {

    .range {
      font-size: .9rem;
      line-height: 1rem;
    }

    .sagittarius {
      width: 60px;
      height: 60px;
    }

    .menu-item {
      width: 60px;
      height: 60px;
    }
  
    .menu-toggler:checked + label:before,
    .menu-toggler:checked + label:after {
      width: 140px;
      height: 140px;
    }
  
    .menu-toggler + label:after {
      width: 140px;
      height: 140px;
    }
  
    .menu-toggler{
      width: 140px;
      height: 140px;
    }
    .menu-toggler + label {
      width: 140px;
      height: 140px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(1){
      background-size: 50px 50px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(2){
      background-size: 50px 50px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(3){
      background-size: 50px 50px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(4){
      background-size: 50px 50px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(5){
      background-size: 50px 50px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(6){
      background-size: 50px 50px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(7){
      background-size: 50px 50px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(8){
      background-size: 50px 50px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(9){
      background-size: 50px 50px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(10){
      background-size: 50px 50px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(11){
      background-size: 50px 50px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(12){
      background-size: 50px 50px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(1):hover{
      background-size: 65px 65px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(2):hover{
      background-size: 65px 65px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(3):hover{
      background-size: 65px 65px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(4):hover{
      background-size: 65px 65px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(5):hover{
      background-size: 65px 65px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(6):hover{
      background-size: 65px 65px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(7):hover{
      background-size: 65px 65px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(8):hover{
      background-size: 65px 65px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(9):hover{
      background-size: 65px 65px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(10):hover{
      background-size: 65px 65px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(11):hover{
      background-size: 65px 65px;
    }
    
    .menu-toggler:checked ~ ul .menu-item:nth-child(12):hover{
      background-size: 65px 65px;
    }

    .nav-item:nth-child(1) a:hover, 
    .nav-item:nth-child(2) a:hover,
    .nav-item:nth-child(3) a:hover,
    .nav-item:nth-child(4) a:hover,
    .nav-item:nth-child(5) a:hover,
    .nav-item:nth-child(6) a:hover,
    .nav-item:nth-child(7) a:hover,
    .nav-item:nth-child(8) a:hover,
    .nav-item:nth-child(9) a:hover,
    .nav-item:nth-child(10) a:hover,
    .nav-item:nth-child(11) a:hover,
    .nav-item:nth-child(12) a:hover {
      background-size: 25px 25px;
    }

    .navbar-brand {
      width: 100%;
      background: url('../public/sun.png') no-repeat left center;
      background-size: contain, cover;
      margin-left: auto;
      margin-right: auto;
    }

    .buttons {
      flex-direction: column !important;
      justify-content: center;
  
    }
    .buttons > a {
      max-width: 100%;
      margin-left:0;
      margin-right: 0;
    }    

    h3 {
      font-size: 1rem;
    }

    .desc {
      font-size: 1.6rem;
      line-height: 1.7rem;
    }
    
    .ml-auto {
      padding-top: .5rem;
      justify-content: center;
      display: flex;
      width: 100%;
    }

  }

@media only screen and (max-width: 399px) {
  .navbar-nav {
    flex-wrap: wrap;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  p {
    font-size: .9rem;
    line-height: 1.1rem;;
  }
  

  footer p {
    font-size: .6rem;
  }

}




@media only screen and (max-width: 320px), 
    screen and (max-height: 299px) {

  h1 {
    font-size: 1.5rem;
  }
    
  h2 {
    font-size: 1.2rem;
  }

  p {
    font-size: .8rem;
    line-height: 1rem;;
  }

  .menu-item {
    width: 40px;
    height: 40px;
  }

  .menu-toggler:checked + label:before,
  .menu-toggler:checked + label:after {
    width: 110px;
    height: 110px;
  }

  .menu-toggler + label:after {
    width: 110px;
    height: 110px;
  }

  .menu-toggler{
    width: 110px;
    height: 110px;
  }
  .menu-toggler + label {
    width: 110px;
    height: 110px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(1){
    background-size: 30px 30px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(2){
    background-size: 30px 30px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(3){
    background-size: 30px 30px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(4){
    background-size: 30px 30px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(5){
    background-size: 30px 30px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(6){
    background-size: 30px 30px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(7){
    background-size: 30px 30px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(8){
    background-size: 30px 30px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(9){
    background-size: 30px 30px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(10){
    background-size: 30px 30px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(11){
    background-size: 30px 30px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(12){
    background-size: 30px 30px;
  }

  .menu-toggler:checked ~ ul .menu-item:nth-child(1):hover{
    background-size: 45px 45px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(2):hover{
    background-size: 45px 45px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(3):hover{
    background-size: 45px 45px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(4):hover{
    background-size: 45px 45px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(5):hover{
    background-size: 45px 45px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(6):hover{
    background-size: 45px 45px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(7):hover{
    background-size: 45px 45px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(8):hover{
    background-size: 45px 45px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(9):hover{
    background-size: 45px 45px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(10):hover{
    background-size: 45px 45px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(11):hover{
    background-size: 45px 45px;
  }
  
  .menu-toggler:checked ~ ul .menu-item:nth-child(12):hover{
    background-size: 45px 45px;
  }
  .aries, .sagittarius, .taurus, .gemini, .cancer, .leo, .virgo, .libra, .scorpio, .capricorn, .aquarius, .pisces {
    display: block;
    width: 30px;
    height: 30px;
    float: unset;
    margin-left: auto;
    margin-right: auto;
  }
  .expand, .col-3, .col-9, .col-2, .col-10 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100%;
    text-align: center;
  }   

  h1 {
    font-size: 2.6rem;
  }

  h2 {
    margin-top: 1.8rem;
    font-size: 2rem;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.2rem;
  }

  .cards {
    flex-direction: column;
  }


  .range {
    margin-bottom: 0rem;
  }

}

@media only screen and (min-width: 999px) { 

  h1 {
    font-size: 5rem;
  }

  .container {
    padding-left: 5%;
    padding-right: 5%;
  }


  .navbar-brand {
    width: 120px;
    height: 120px;
    max-width: 120px;
  }
}

